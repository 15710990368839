<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="updateDialog"
        transition="dialog-bottom-transition"
        max-width="900"
        persistent
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Modificar Movimiento de Entrada {{ body.account.bank.name }} -
              {{ body.account.code }}</v-toolbar
            >
            <p v-if="responseError && !valid">
              Los datos enviados no son correctos, haga click sobre la X de los
              campos inválidos para intentar de nuevo.
            </p>
            <v-container>
              <v-progress-linear
                v-if="loading"
                :loading="loading"
                indeterminate
              ></v-progress-linear>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <reason-movement-field
                      v-if="isCRE != undefined"
                      :kind="isCRE"
                      v-model="reason"
                      :rules="reasonError"
                      :error-messages="reasonError"
                      @clear="reasonError = []"
                      clearable
                    ></reason-movement-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <date-field
                      label="Fecha"
                      v-model="body.movement_date"
                      :rules="movementDateError"
                      :error-messages="movementDateError"
                      @clear="movementDateError = []"
                      clearable
                    ></date-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-checkbox v-model="checkbox" label="Cheque"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="!checkbox"
                      label="No. Boleta"
                      v-model="body.code"
                      :rules="codeError"
                      :error-messages="codeError"
                      @click:clear="codeError = []"
                      clearable
                    ></v-text-field>
                    <v-text-field
                      v-if="checkbox"
                      label="No. Cheque"
                      v-model="body.check_code"
                      :rules="codeError"
                      :error-messages="codeError"
                      @click:clear="codeError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-if="isCRE == 'CRE'"
                      label="Valor"
                      v-model="body.cre"
                      :rules="creError"
                      :error_messages="creError"
                      @click:clear="creError = []"
                      clearable
                    ></v-text-field>
                    <v-text-field
                      v-if="isCRE == 'DEB'"
                      label="Valor"
                      v-model="body.deb"
                      :rules="creError"
                      :error_messages="creError"
                      @click:clear="creError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Descripción"
                      v-model="body.description"
                      :rules="descriptionError"
                      :error-messages="descriptionError"
                      @click:clear="descriptionError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" v-if="showDocumentField">
                    <v-text-field
                      :label="reason.document_required_name"
                      v-model="body.document"
                      :rules="documentError"
                      :error-messages="documentError"
                      @click:clear="documentError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <tt-field
                      v-model="body.tt_id"
                      :credeb="body.credeb"
                    ></tt-field>
                  </v-col>
                  <v-col
                    v-if="body.tt_id != undefined && body.tt_id == 100"
                    cols="12"
                    md="6"
                  >
                    <v-checkbox
                      v-model="body.payable"
                      label="Cheque en Circulación"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-switch
                  v-if="body.credeb == 'DEB'"
                  label="Agregar Detalle de Egreso"
                  v-model="thereAredetailReason"
                ></v-switch>
                <v-divider></v-divider>
                <v-row v-if="thereAredetailReason">
                  <v-col cols="12" md="4">
                    <reason-movement-field
                      v-model="detailReason.reason_id"
                      kind="DEB"
                      :filterReason="true"
                      :reset="reset"
                    ></reason-movement-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Monto"
                      v-model="detailReason.amount"
                      ref="amount"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn
                      elevation="2"
                      large
                      color="info"
                      @click="addReason()"
                    >
                      Agregar</v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-data-table
                  v-if="thereAredetailReason"
                  :headers="headers"
                  :items="body.accountmovementdetail"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    Q {{ item.amount }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon @click="removeReason(item.id)" color="red darken-2"
                      >mdi-delete-outline</v-icon
                    >
                  </template>
                </v-data-table>
                <v-row v-if="thereAredetailReason">
                  <v-col cols="12" md="8" class="text-center">TOTAL</v-col>
                  <v-col cols="12" md="2" class="text-center"
                    ><b> Q {{ totalReason }}</b></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <strong>
                      <v-checkbox
                        v-model="body.is_confirmed"
                        label="Confirmar Movimiento"
                      ></v-checkbox>
                    </strong>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="yellow" @click="close()"> Cancelar </v-btn>
                  <v-btn
                    color="success"
                    @click="updateMovementIn()"
                    :disabled="!valid"
                  >
                    Actualizar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  props: {
    updateDialog: { type: Boolean, required: true },
    movementInId: { type: String, required: true },
  },
  components: {
    ReasonMovementField: () =>
      import("@/components/fields/ReasonMovementField"),
    DateField: () => import("@/components/fields/DateField"),
    TtField: () => import("@/components/fields/TTField"),
  },
  data() {
    return {
      payable: false,
      reset: false,
      thereAredetailReason: false,
      checkbox: false,
      isCRE: undefined,
      loading: true,
      reasonError: [],
      movementDateError: [],
      codeError: [],
      creError: [],
      descriptionError: [],
      documentError: [],
      responseError: false,
      reason: {},
      valid: false,
      showDocumentField: false,
      body: {
        account: {
          id: "",
          bank: {
            id: 0,
            name: "",
          },
        },
        document: "",
        account_id: 0,
        reason_id: 0,
        code: null,
        check_code: null,
        description: "",
        movement_date: "",
        cre: null,
        deb: null,
        credeb: "",
        payable: false,
      },
      // detail
      detailReason: {
        reason_id: undefined,
        amount: 0,
      },
      totalReason: 0,
      headers: [
        {
          text: "Tipo Ingreso",
          align: "start",
          value: "reason.reason",
        },
        {
          text: "Descripción",
          align: "start",
          value: "reason.description",
        },
        {
          text: "Monto",
          value: "amount",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      //form rules
      textRules: [rules.required, rules.minLength(5)],
      onlyRequired: [rules.required],
      valueRules: [rules.required, rules.minValue(0.1)],
    };
  },
  methods: {
    getMovementIn() {
      this.loading = true;
      requests
        .get("api/accounts/movements/" + this.movementInId + "/")
        .then((res) => {
          this.body = res.data;
          this.isCRE = this.body.credeb;
          this.reason = res.data.reason;
          this.loading = false;
          this.checkbox = this.body.code != null ? false : true;
          this.thereAredetailReason = this.body.reason_detail;
          this.calculateTotalReason(this.body.accountmovementdetail);
        });
    },
    addReason() {
      let data = {};
      if (
        this.detailReason.amount > 0 &&
        this.detailReason.reason_id != undefined
      ) {
        data = {
          accountmovement_id: this.body.id,
          reason_id: this.detailReason.reason_id.id,
          amount: this.detailReason.amount,
        };
        requests.post("api/accounts/movements-dt/", data).then((response) => {
          if (response.status == 201) {
            this.$toasted.global.info({
              message: "Registro Creado",
            });
            this.getMovementIn();
          } else {
            this.$toasted.global.error({
              message: response.data.detail,
            });
          }
        });
        this.reset = this.reset ? false : true;
        this.$refs.amount.reset();
        this.detailReason = {
          reason_id: {},
          amount: 0,
        };
      }
    },
    removeReason(id) {
      requests.delete("api/accounts/movements-dt/" + id).then((resp) => {
        if (resp.status == 204) {
          this.$toasted.global.info({
            message: "Registro Eliminado",
          });
          this.getMovementIn();
        }
      });
    },
    updateMovementIn() {
      if (
        this.thereAredetailReason &&
        this.body.accountmovementdetail.length == 0
      ) {
        return this.$toasted.global.error({
          message: "Debe agregar al menos un detalle",
        });
      }
      this.valid = false;
      let data = {
        reason_id: this.body.reason_id,
        description: this.body.description,
        movement_date: this.body.movement_date,
        document:
          this.body.document == undefined ? "" : this.body.document.trim(),
        reason_detail: this.thereAredetailReason,
        tt_id: this.body.tt_id,
        payable: this.body.payable,
        is_confirmed: this.body.is_confirmed,
      };
      if (this.body.credeb == "CRE") {
        data.cre = this.body.cre;
      } else if (this.body.credeb == "DEB") {
        data.deb = this.body.deb;
      }
      if (this.checkbox) {
        data.check_code = this.body.check_code;
      } else {
        data.code = this.body.code;
      }
      requests
        .patch("api/accounts/movements-in/" + this.movementInId + "/", data)
        .then((res) => {
          if (res.status == 200) {
            this.close();
            this.$toasted.global.info({
              message: "Movimiento Actualizado",
            });
            this.$emit("updated");
          } else if (res.status == 400) {
            this.responseError = true;

            if (res.data.reason_id != undefined) {
              this.$toasted.global.info({
                message: res.data.reason_id,
              });
              this.reasonError = res.data.reason_id;
            }
            if (res.data.movement_date != undefined) {
              this.movementDateError = res.data.movement_date;
            }
            if (res.data.code != undefined) {
              this.codeError = res.data.code;
            }
            if (res.data.cre != undefined) {
              this.creError = res.data.cre;
            }
            if (res.data.description != undefined) {
              this.descriptionError = res.data.description;
            }
            if (res.data.document != undefined) {
              this.documentError = [res.data.document];
            }
            if (res.data.detail != undefined) {
              this.$toasted.global.info({
                message: res.data.detail,
              });
              this.valid = true;
            }
          }
        });
    },
    close() {
      this.body = {
        account: {
          id: "",
          bank: {
            id: 0,
            name: "",
          },
        },
        document: "",
        account_id: 0,
        reason_id: 0,
        code: "",
        description: "",
        movement_date: "",
        cre: "",
      };
      this.reasonError = [];
      this.movementDateError = [];
      this.codeError = [];
      this.creError = [];
      this.descriptionError = [];
      this.documentError = [];
      this.$emit("close");
    },
    calculateTotalReason(data) {
      this.totalReason = 0;
      data.forEach((el) => {
        this.totalReason = (
          Number(this.totalReason) + Number(el.amount)
        ).toFixed(2);
      });
    },
  },
  watch: {
    reason(newValue) {
      if (typeof newValue === "object") {
        if (Object.keys(newValue).length > 0) {
          this.body.reason_id = newValue.id;

          this.showDocumentField = newValue.document_required_name;
        }
      }
    },
  },
  mounted() {
    this.getMovementIn();
  },
};
</script>